<template>
  <div
    :class="wrapperClasses"
    class="e-overall"
  >
    <transition name="canvas">
      <div
        v-show="!offcanvasOpen"
        class="e-canvas"
      >
        <div
          v-if="!userInfo || !userInfo.id"
          class="e-canvas__background"
        />

        <TheHeader />

        <div class="e-content">
          <TheSidebar />
          <VuePerfectScrollbar class="e-content__inner e-content__inner--wide">
            <div class="e-content__scroll">
              <div class="e-module e-module--limited e-module--landing">
                <article class="e-module__column">
                  <div class="e-module__icon">
                    <icon-mailsupport />
                  </div>
                  <div class="e-module__header">
                    <h1 class="e-module__title">
                      em@il-Beratung
                    </h1>
                  </div>
                  <div
                    class="e-module__content"
                    v-html="mailText"
                  />
                  <footer class="e-module__footer">
                    <router-link
                      :to="{name: 'dialogs'}"
                      class="e-btn e-btn--tertiary"
                    >
                      zur em@il-Beratung
                    </router-link>
                  </footer>
                </article>
                <article
                  v-if="!settings.chatDisabled"
                  :class="{
                    'e-chat--offline': !chatIsOpen
                  }"
                  class="e-module__column"
                >
                  <div class="e-module__icon">
                    <icon-chat />
                  </div>
                  <div class="e-module__header">
                    <h1 class="e-module__title">
                      Live-Chat
                    </h1>
                  </div>
                  <div class="e-module__content">
                    <div v-html="chatText" />
                    <table v-if="!chatIsOpen && upcomingchatEvents.length">
                      <tbody>
                        <tr
                          v-for="chatEvent in sortedUpcomingChatEvents"
                          :key="chatEvent.id"
                        >
                          <td valign="top">
                            {{ format(chatEvent.beginning, 'EEEE, dd.MM.') }}
                            {{ format(chatEvent.beginning, 'HH:mm') }}-{{ format(chatEvent.end, 'HH:mm') }} Uhr | Thema: {{ chatEvent.subject }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <footer class="e-module__footer">
                    <div class="link">
                      <button
                        v-if="!chatIsOpen"
                        :aria-disabled="!chatIsOpen"
                        :disabled="!chatIsOpen"
                        class="e-btn is-disabled"
                      >
                        zum Live-Chat
                      </button>
                      <router-link
                        v-else
                        :to="{name: 'liveChat'}"
                        class="e-btn e-btn--tertiary"
                      >
                        zum Live-Chat
                      </router-link>
                    </div>
                    <!-- <div class="chat-archive-link">
                      <router-link
                        v-if="userInfo && userInfo.chatEventCount>0"
                        :to="{name: 'chatDialogs'}"
                        class=""
                      >
                        Meine archivierten Chats
                      </router-link>
                    </div> -->
                  </footer>
                </article>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>

        <TheFooter />
      </div>
    </transition>

    <transition name="offcanvas">
      <TheOffcanvas v-show="offcanvasOpen" />
    </transition>

    <ChatIdleConfirm />
    <slot></slot>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns'
import deLocale from 'date-fns/locale/de'

import { mapGetters } from 'vuex'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import TheFooter from 'molecules/TheFooter/TheFooter'
import TheHeader from 'molecules/TheHeader/TheHeader'
import TheSidebar from 'molecules/TheSidebar/TheSidebar'
import TheOffcanvas from 'molecules/TheOffcanvas/TheOffcanvas'
import ChatIdleConfirm from 'organisms/ChatIdleConfirm/ChatIdleConfirm'
import IconChat from 'assets/icons/chat-solid.svg'
import IconMailsupport from 'assets/icons/mailsupport.svg'

export default {
  components: {
    IconChat,
    IconMailsupport,
    ChatIdleConfirm,
    TheFooter,
    TheHeader,
    TheSidebar,
    TheOffcanvas,
    VuePerfectScrollbar
  },

  data: () => ({
    loading: false
  }),

  computed: {
    ...mapGetters([
      'announcement', 'chatIsOpen', 'currentChatEvent', 'userInfo', 'offcanvasOpen', 'settings']),
    mailText () {
      return window.landingTexts.email
    },
    chatText () {
      return this.chatIsOpen ? window.landingTexts.chatOpen + '<br>Thema: ' + ` ${this.currentChatEvent.subject}` : window.landingTexts.chatClosed
    },
    upcomingchatEvents () {
      return window.upcomingChatEvents
    },
    sortedUpcomingChatEvents () {
      return [...window.upcomingChatEvents].sort((a, b) =>
        new Date(a.beginning) - new Date(b.beginning)
      )
    },
    wrapperClasses () {
      let classes = {
        'e-overall--loggedin': this.userInfo && this.userInfo.id
      }
      return classes
    }
  },

  methods: {
    format (date, token) {
      return format(parseISO(date), token, {
        locale: deLocale
      })
    }
  }

}
</script>

<style lang="scss" scoped>
@import '_assets/LoginLanding';
</style>
